<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ `${cafeteria.name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ cafeteria.name }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ESTABLISHMENT") }}</dt>
          <dd class="col-sm-8">
            <establishment :establishment="cafeteria.establishment" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="cafeteria.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ cafeteria.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ cafeteria.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Establishment from "@/components/Establishment.vue";

export default {
  name: "cafeteria-view-global",
  components: { Establishment },
  props: ["cafeteria"],

  data() {
    return {};
  },

  created() {},

  methods: {
    cafeteriaUpdated() {
      this.$emit("cafeteriaUpdated", true);
    },
  },

  mounted() {},

  watch: {
    cafeteria(cafeteria) {},
  },
};
</script>
